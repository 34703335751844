/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { BackButton, useInitData } from '@vkruglikov/react-telegram-web-app';
import Typography from 'antd/es/typography';
import { AccountContext } from './context';
import Spin from 'antd/es/spin';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js';
import { color as ChartJSHelpersColor } from 'chart.js/helpers';
import { Bar, Pie } from 'react-chartjs-2';
import DineroFactory from 'dinero.js';
import dayjs from 'dayjs';
import DatePicker from 'antd/es/date-picker';
import autocolors from 'chartjs-plugin-autocolors';
import Statistic from 'antd/es/statistic';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend, autocolors);

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function Dashboard() {
  const [initDataUnsafe] = useInitData();
  // const webApp = useWebApp();
  const account = useContext(AccountContext);
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [summary, setSummary] = useState<{
    total_amount: number,
    total_expenses: number,
    total_expenses_by_category: Array<{ category: string; total_expenses: number }>,
    total_amount_by_category: Array<{ category: string; total_amount: number }>,
  } | null>(null);
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const response = await fetch(`${BASE_URL}/api/web/summary`, {
          method: "POST",
          body: JSON.stringify({
            action: "GET",
            data: initDataUnsafe,
            account_id: account.id,
            month: date.month() + 1,
            year: date.year(),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const json = await response.json();

        if (json) {
          setSummary(json);
        }
      } catch (e) {
        alert(e);
      }

      setLoading(false);
    })();
  }, [initDataUnsafe, account, date]);

  function onDateChange(date: dayjs.Dayjs | null) {
    if (!date) {
      return;
    }

    setDate(date);
  }

  return (
    <div>
      <Typography.Title level={3}>Summary</Typography.Title>
      {!summary || isLoading ? (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <DatePicker
            onChange={onDateChange}
            picker="month"
            value={date}
            style={{ marginBottom: 24 }}
          />

          <Statistic title={`Total Expenses (${account.currency})`} value={DineroFactory({ currency: account.currency as DineroFactory.Currency, amount: Math.round(+summary.total_amount.toFixed(3) * 1000), precision: 3 }).toFormat("$0,0.00")} />
          <PieChart
            data={summary.total_amount_by_category.map(d => ({ ...d, total_amount: DineroFactory({ currency: account.currency as DineroFactory.Currency, amount: Math.round(+d.total_amount.toFixed(3) * 1000), precision: 3 }).toUnit() }))}
            label="category"
            value="total_amount"
            valueLabel="Total Amount"
          />
          <StackedBarChart
            dataset={[
              {
                label: `Expenses (${account.currency})`,
                data: summary.total_amount_by_category.map(d => ({
                  ...d,
                  total_amount: DineroFactory({
                    currency: account.currency as DineroFactory.Currency,
                    amount: Math.round(+d.total_amount.toFixed(3) * 1000),
                    precision: 3,
                  }).toUnit(),
                })),
              },
              {
                label: `Budget Left (${account.currency})`,
                data: summary.total_amount_by_category.map(d => {
                  const budget: number | undefined = account.category_budgets[d.category];
                  if (!budget) {
                    return {
                      ...d,
                      total_amount: 0,
                    };
                  }

                  return {
                    ...d,
                    total_amount: DineroFactory({
                      currency: account.currency as DineroFactory.Currency,
                      amount: Math.round(+budget.toFixed(3) * 1000),
                      precision: 3,
                    }).subtract(DineroFactory({
                      currency: account.currency as DineroFactory.Currency,
                      amount: Math.round(+d.total_amount.toFixed(3) * 1000),
                      precision: 3,
                    })).toUnit(),
                  };
                }),
              },
            ]}
            label="category"
            value="total_amount"
          />
          <Statistic title="No. of Expenses" value={summary.total_expenses} />
          <BarChart
            data={summary.total_expenses_by_category}
            label="category"
            value="total_expenses"
            valueLabel="Total Expenses"
          />
        </>
      )}
      {history.length > 1 && <BackButton onClick={() => window.history.back()} />}
    </div>
  )
}

const lighten = (color: string) =>
  ChartJSHelpersColor(color)
    .saturate(0.5)
    .lighten(0.6)
    .rgbString();

const PieChart = ({ data, label, value, valueLabel }: { data: Array<object>, label: string, value: string, valueLabel: string }) => {
  return <Pie
    options={{
      plugins: {
        autocolors: {
          mode: 'data',
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background),
              border: lighten(colors.border)
            };
          },
        },
      },
    }}
    data={{
      labels: data.map(d => (d as any)[label]),
      datasets: [
        {
          label: valueLabel,
          data: data.map(d => (d as any)[value]),
          borderWidth: 1,
        },
      ],
    }}
  />;
};

const BarChart = ({ data, label, value, valueLabel }: { data: Array<object>, label: string, value: string, valueLabel: string }) => {
  return <Bar
    options={{
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        autocolors: {
          mode: 'data',
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background),
              border: lighten(colors.border)
            };
          },
        },
      },
    }}
    data={{
      labels: data.map(d => (d as any)[label]),
      datasets: [
        {
          label: valueLabel,
          data: data.map(d => (d as any)[value]),
          borderWidth: 1,
        },
      ],
    }}
  />;
};

const StackedBarChart = ({ dataset, label, value }: { dataset: Array<{ label: string, data: Array<object> }>, label: string, value: string }) => {
  return <Bar
    options={{
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        autocolors: {
          mode: 'data',
          customize(context) {
            const colors = context.colors;
            return {
              background: lighten(colors.background),
              border: lighten(colors.border)
            };
          },
        },
      },
    }}
    data={{
      labels: dataset[0].data.map(d => (d as any)[label]),
      datasets: dataset.map(data => ({
          label: data.label,
          data: data.data.map(d => (d as any)[value]),
          borderWidth: 1,
      })),
    }}
  />;
};