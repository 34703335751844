import { InputNumber, Table, TableProps } from 'antd';
import Form, { FormInstance } from 'antd/es/form';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import cc from "currency-codes";

export function AccountForm({ 
  form,
  canEditCurrency,
  save,
  account,
  isLoading,
 }: {
   form: FormInstance,
   canEditCurrency: boolean,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   account: any,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   save: (values: any) => void,
   isLoading: boolean,
 }) {
  const categories: string[] | undefined = Form.useWatch("categories", { form, preserve: true });
  const categoryBudgets: Record<string, number> | undefined = Form.useWatch("category_budgets", { form, preserve: true });
  const [tableData, setTableData] = useState<DataType[]>([]);

  useEffect(() => {
    const tableData: DataType[] = categories
      ?.sort((a, b) => a.localeCompare(b))
      .map((category: string) => ({
        category,
        budget: categoryBudgets?.[category] ?? 0,
        currency: account.currency,
      })) ?? [];
    setTableData(tableData);
  }, [categories, categoryBudgets, account.currency]);

  return (
    <Form
      form={form}
      name="EditAccount"
      layout="vertical"
      autoComplete="off"
      onFinish={save}
      initialValues={Object.assign(
        {
          currency: account.currency,
          categories: [...account.categories],
          category_budgets: Object.assign(
            (account.categories as string[]).reduce((acc, cur) => {
              acc[cur] = account.category_budgets[cur] ?? 0;
              return acc;
            }, {} as Record<string, number>), account.category_budgets),
          timezone: account.timezone,
        },
      )}
      disabled={isLoading}
    >
      <Form.Item
        label="Currency"
        name="currency"
        rules={[{ required: true, message: "Currency" }]}
        required
      >
        <Select
          showSearch
          style={{ width: 120 }}
          options={cc.codes().map(code => ({ value: code }))}
          size="large"
          optionFilterProp="children"
          filterOption={(input: string, option?: { value: string }) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
          disabled={!canEditCurrency}
        />
      </Form.Item>
      <Form.Item
        label="Timezone"
        name="timezone"
        rules={[{ required: true, message: "Please select a timezone." }]}
        required
      >
        <Select
          showSearch
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={((Intl as any).supportedValuesOf('timeZone') as string[])
            .map((tz) => ({ value: tz }))
            .sort((a, b) => a.value.localeCompare(b.value))
          }
          optionFilterProp="children"
          filterOption={(input: string, option?: { value: string }) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Categories & Budgets"
      >
        <Table
          columns={columns}
          dataSource={tableData}
          size="small"
          pagination={false}
        />
      </Form.Item>
    </Form>
  );
}

interface DataType {
  category: string;
  budget: number;
  currency: string;
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    render: (text: string) => text,
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    key: 'budget',
    render: (_val, record) => (
      <Form.Item
        name={["category_budgets", record.category]}
        noStyle
      >
        <InputNumber size="small" min={0} addonBefore={record.currency} />
      </Form.Item>
    )
  },
];
