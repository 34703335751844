import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import InputNumber from 'antd/es/input-number';
import { pick } from 'lodash';
import dayjs from 'dayjs';
import Select from 'antd/es/select';
import cc from "currency-codes";
import TextArea from 'antd/es/input/TextArea';
import { AutoComplete, TimePicker } from 'antd';
import { useState } from 'react';

export function ExpenseForm({ 
  form,
  expense,
  categories,
  defaultCurrency,
  cards,
  save,
  isLoading,
 }: {
   form: FormInstance,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   expense: any,
   categories: string[],
   defaultCurrency: string,
   cards: string[],
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   save: (values: any) => void,
   isLoading: boolean,
 }) {
  const [search, setSearch] = useState<string>("");
  const paymentMethod: string | undefined = Form.useWatch("payment_method", { form, preserve: true });

  return (
    <Form
      form={form}
      name="EditExpense"
      layout="vertical"
      autoComplete="off"
      onFinish={save}
      initialValues={Object.assign(
        {
          date: dayjs(expense.date),
          time: dayjs(expense.date),
          created_at: dayjs(expense.created_at),
          updated_at: dayjs(expense.updated_at),
        },
        pick(expense, ['description', 'category', 'total_amount', 'payment_method', 'card_no', 'notes']),
        { currency: expense.currency ?? defaultCurrency },
      )}
      disabled={isLoading}
    >
      <Form.Item
        label="Date"
        name="date"
        rules={[{ required: true, message: "Please select a date." }]}
        required
      >
        <DatePicker maxDate={dayjs()} size="large" />
      </Form.Item>
      <Form.Item
        label="Time"
        name="time"
        rules={[{ required: true, message: "Please select a time." }]}
        required
      >
        <TimePicker maxDate={dayjs()} size="large" />
      </Form.Item>
      <Form.Item
        label="Category"
        name="category"
        rules={[{ required: true, message: "Please select a category." }]}
        required
      >
        <Select
          showSearch
          options={categories.map(category => ({ value: category })).sort((a, b) => a.value.localeCompare(b.value))}
          optionFilterProp="children"
          filterOption={(input: string, option?: { value: string }) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Amount"
        name="total_amount"
        rules={[{ required: true, message: "Please enter an amount." }]}
        required
      >
        <InputNumber
          placeholder="Amount"
          addonBefore={<Form.Item
            name="currency"
            noStyle
          >
            <Select
              style={{ width: 120 }}
              options={cc.codes().map(code => ({ value: code }))}
              size="large"
              disabled={true}
            />
          </Form.Item>}
          min={0}
          size="large"
        // formatter={(value) => `${form.getFieldsValue([['currency']]) ?? "$"} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as any}
        />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ max: 255, message: "Description must be less than 255 characters." }]}
      >
        <Input placeholder="Lunch" size="large" />
      </Form.Item>
      <Form.Item
        label="Payment Method"
        name="payment_method"
      >
        <Select
          options={[
            { value: 'Visa' },
            { value: 'Mastercard' },
            { value: 'American Express' },
            { value: 'Discover' },
            { value: 'PayNow' },
            { value: 'QRCode' },
            { value: 'Others' },
            { value: '' },
          ]}
          size="large"
        />
      </Form.Item>
      <Form.Item
        label="Card No (last 4)"
        name="card_no"
        hidden={!["Visa", "Mastercard", "American Express", "Discover"].includes(paymentMethod ?? "")}
        rules={[
          { len: 4, message: "Card No must be 4 digits" },
        ]}
      >
        <AutoComplete
          options={cards
            .filter(card => `${card}`.includes(search ? search : form.getFieldValue("card_no") ?? ""))
            .map(card => ({ value: card }))
          }
          onSearch={(value) => {
            setSearch(value);
          }}
        >
          <Input
            type="number"
            placeholder="9876"
            size="large"
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item
        label="Notes"
        name="notes"
        rules={[{ max: 255, message: "Notes must be less than 255 characters." }]}
      >
        <TextArea size="large" rows={4} />
      </Form.Item>
      <Form.Item
        label="Created At"
        name="created_at"
      >
        <DatePicker showTime size="large" disabled={true} />
      </Form.Item>
      <Form.Item
        label="Updated At"
        name="updated_at"
      >
        <DatePicker showTime size="large" disabled={true} />
      </Form.Item>
    </Form>
  );
}
