import { red } from "@ant-design/colors";
import { MainButton, useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import Spin from "antd/es/spin";
import Typography from "antd/es/typography";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function Delete({ expenseId }: { expenseId: string }) {
  const [initDataUnsafe] = useInitData();
  const webApp = useWebApp();
  const expense_id = expenseId;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expense, setExpense] = useState<any | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!expense_id) {
        return;
      }

      setLoading(true);

      try {
        const expense_response = await fetch(`${BASE_URL}/api/web/expense`, {
          method: "POST",
          body: JSON.stringify({
            action: "GET",
            data: initDataUnsafe,
            expense_id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const json = await expense_response.json();

        if (!json) {
          webApp.showAlert("Expense not found. It may have already been deleted.");
          webApp.close();
        } else {
          setExpense(json);
        }
      } catch (e) {
        alert(e);
      }

      setLoading(false);
    })();
  }, [initDataUnsafe, expense_id, webApp]);

  const delete_expense = async () => {
    setLoading(true);

    try {
      await fetch(`${BASE_URL}/api/web/expense`, {
        method: "POST",
        body: JSON.stringify({
          action: "DELETE",
          data: initDataUnsafe,
          expense_id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      webApp.close();
    } catch (e) {
      alert(e);
    }

    setLoading(false);
  };

  if (isLoading || !expense) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Typography.Title level={3}>Delete Expense</Typography.Title>
      <Typography.Paragraph>Are you sure you want to delete this expense?</Typography.Paragraph>
      <Typography.Paragraph>
        <b>{expense.description ? expense.description + " - " : ""}{expense.category}</b><br />
        {dayjs(expense.date).format("YYYY-MM-DD")}<br />
        {expense.currency} {expense.total_amount}
      </Typography.Paragraph>
      <MainButton text="Confirm" color={red[4]} onClick={() => delete_expense()} />
    </div>
  );
}
