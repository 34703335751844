import { useContext, useEffect, useState } from 'react';
import { BackButton, MainButton, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useForm } from 'antd/es/form/Form';
import Typography from 'antd/es/typography';
import { ExpenseForm } from './ExpenseForm';
import { AccountContext } from './context';
import { Dayjs } from 'dayjs';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function New() {
  const [initDataUnsafe] = useInitData();
  const webApp = useWebApp();
  const [form] = useForm();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const account = useContext(AccountContext);
  const [isLoading, setLoading] = useState(false);
  const [cards, setCards] = useState<string[]>([]);

  useEffect(() => {
    webApp.enableClosingConfirmation();
  }, [webApp]);

  useEffect(() => {
    fetch(`${BASE_URL}/api/web/cards`, {
      method: "POST",
      body: JSON.stringify({
        action: "GET",
        data: initDataUnsafe,
        account_id: account.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        setCards(body);
      })
      .catch(() => {
      });
  }, [account.id, initDataUnsafe]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const save = async (values: any) => {
    const time: Dayjs = values.time;
    const date = (values.date as Dayjs)
      .set("hours", time.hour())
      .set("minutes", time.minute())
      .set("seconds", time.second())
      .set("milliseconds", time.millisecond())
      .format(); 
    const expense_input = {
      ...values,
      date,
    };

    setLoading(true);

    try {
      await fetch(`${BASE_URL}/api/web/expense`, {
        method: "POST",
        body: JSON.stringify({
          action: "POST",
          data: initDataUnsafe,
          account_id: account.id,
          expense_input,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (history.length > 1) {
        window.history.back();
      } else {
        webApp.close();
      }
    } catch (e) {
      alert(e);
    }

    setLoading(false);
  };

  return (
    <div>
      <Typography.Title level={3}>Create Expense</Typography.Title>
      <ExpenseForm
        form={form}
        cards={cards}
        expense={{}}
        categories={account.categories ?? []}
        defaultCurrency={account.currency}
        save={save}
        isLoading={isLoading}
      />
      <MainButton text="Create" disabled={isLoading} onClick={() => form.submit()} />
      {history.length > 1 && <BackButton onClick={() => window.history.back()} />}
    </div>
  )
}
