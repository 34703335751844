import { useExpand, useInitData, useThemeParams } from '@vkruglikov/react-telegram-web-app';
import ConfigProvider from 'antd/es/config-provider';
import theme from 'antd/es/theme';
import { Route, Switch, useLocation } from 'wouter';
import { Edit } from './Edit';
import { New } from './New';
import { Delete } from './Delete';
import { ExpensesList } from './ExpensesList';
import { useEffect, useState } from 'react';
import Spin from 'antd/es/spin';
import { AccountContext } from './context';
import { Dashboard } from './Dashboard';
import short from "short-uuid";
import { EditAccount } from './EditAccount';
import * as Sentry from '@sentry/react';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

function App() {
  const [colorScheme, themeParams] = useThemeParams();
  const [initDataUnsafe] = useInitData();
  const [, expand] = useExpand();
  const [, setLocation] = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [account, setAccount] = useState<any | null>(null);
  const [account_id, setAccountId] = useState<string | null | undefined>(undefined);
  const [expenseId, setExpenseId] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    expand();
  }, [expand]);

  useEffect(() => {
    if (initDataUnsafe?.start_param) {
      if (initDataUnsafe.start_param.startsWith('e-')) {
        const expense_id = short().toUUID(initDataUnsafe.start_param.substring(2));
        setExpenseId(expense_id || null);
      } else {
        const account_id = short().toUUID(initDataUnsafe.start_param);
        setAccountId(account_id || null);
      }
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const account_id = urlParams.get('account_id');
      setAccountId(account_id || null);
    }

  }, [initDataUnsafe?.start_param, setLocation])

  useEffect(() => {
    if (expenseId === undefined) {
      return;
    }

    (async () => {
      try {
        const account_response = await fetch(`${BASE_URL}/api/web/account`, {
          method: "POST",
          body: JSON.stringify({
            action: "GET",
            data: initDataUnsafe,
            expense_id: expenseId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const json = await account_response.json();

        if (json) {
          setAccount(json);
          setAccountId(json.id);
          setLocation(`/edit/${expenseId}`);
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [initDataUnsafe, expenseId, setLocation]);

  useEffect(() => {
    if (account_id === undefined) {
      return;
    }

    if (account_id) {
      Sentry.setUser({ id: account_id });
    }

    (async () => {
      try {
        const account_response = await fetch(`${BASE_URL}/api/web/account`, {
          method: "POST",
          body: JSON.stringify({
            action: "GET",
            data: initDataUnsafe,
            account_id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const json = await account_response.json();

        if (json) {
          setAccount(json);
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [initDataUnsafe, account_id]);

  if (account_id === undefined) {
    return null;
  }

  return (
    <div>
      <ConfigProvider
        theme={
          themeParams.text_color
            ? {
              algorithm:
                colorScheme === 'dark'
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
              token: {
                colorText: themeParams.text_color,
                colorPrimary: themeParams.button_color,
                colorBgBase: themeParams.bg_color,
              },
            }
            : undefined
        }
      >
        { !account ? (
          <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <AccountContext.Provider value={account}>
              <Switch>
                <Route path="/edit/:expense_id">
                  {(params) => <Edit expenseId={params.expense_id} />}
                </Route>

                <Route path="/delete/:expense_id">
                  {(params) => <Delete expenseId={params.expense_id} />}
                </Route>

                <Route path="/new">
                  <New />
                </Route>

                <Route path="/dashboard">
                  <Dashboard />
                </Route>

                <Route path="/account">
                  <EditAccount />
                </Route>

                <Route path="/">
                  <ExpensesList />
                </Route>

                <Route>404: No such page!</Route>
              </Switch>
          </AccountContext.Provider>
        ) }
      </ConfigProvider>
    </div>
  )
}
export default App
