import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Flex, FloatButton, List, Spin, Typography } from "antd";
import { useLocation } from "wouter";
import { BackButton, MainButton, useInitData, useWebApp } from "@vkruglikov/react-telegram-web-app";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import { AccountContext } from "./context";
import { PieChartOutlined } from '@ant-design/icons';
import { SettingOutlined } from '@ant-design/icons';
import DineroFactory from "dinero.js";
import { Ripples } from "react-ripples-continued";

interface DataType {
  id: string;
  date: string;
  category: string;
  shop: string | null;
  description: string;
  ref: string | null;
  service_charge: number;
  delivery_fee: number;
  discount: number;
  subtotal_amount: number;
  total_amount: number;
  tax_amount: number;
  currency: string;
  card_no: string | null;
  payment_method: string | null;
  markdown: string;
  notes: string;
  account_id: string;
  created_at: string;
  updated_at: string;
}

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function ExpensesList() {
  const [initDataUnsafe] = useInitData();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [data, setData] = useState<DataType[]>([]);
  const [, setLocation] = useLocation();
  const account = useContext(AccountContext);
  const webApp = useWebApp();

  const newExpense = useCallback(() => {
    setLocation("/new");
  }, [setLocation]);

  const goToDashboard = useCallback(() => {
    setLocation("/dashboard");
  }, [setLocation]);

  const goToAccount = useCallback(() => {
    setLocation("/account");
  }, [setLocation]);

  useEffect(() => {
    webApp.disableClosingConfirmation();
  }, [webApp]);

  const loadMoreData = () => {
    if (loading || page < 0) {
      return;
    }

    setLoading(true);

    fetch(`${BASE_URL}/api/web/expense`, {
        method: "POST",
        body: JSON.stringify({
          action: "GET",
          data: initDataUnsafe,
          page,
          account_id: account.id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
    .then((res) => res.json())
    .then((body) => {
      setData([...data, ...body]);
      setLoading(false);
      setInitLoading(false);
      if (body.length >= 10) {
        setPage(page + 1);
      } else {
        setPage(-1);
      }
    })
    .catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreComponent =
    !initLoading && !loading && page >= 0 ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={loadMoreData}>load more</Button>
      </div>
    ) : !initLoading && loading ? (<div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Spin size="large" />
      </div>) : null;

   return (
    <div>
       <List
         loading={initLoading}
         itemLayout="horizontal"
         loadMore={loadMoreComponent}
         dataSource={Object.values(groupBy(data, (item) => dayjs(item.date).format("YYYY-MM-DD")))}
         renderItem={(items) => <ExpenseSection currency={account.currency} items={items} onItemClick={(item) => setLocation(`/edit/${item.id}`)} />}
      />
      <MainButton text="New Expense" disabled={loading} onClick={() => newExpense()} />
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton type="primary" icon={<PieChartOutlined />} onClick={() => goToDashboard()} />
        <FloatButton icon={<SettingOutlined />} onClick={() => goToAccount()} />
      </FloatButton.Group>
      <BackButton onClick={() => webApp.close()} />
    </div>
  );
}

function ExpenseSection({ currency, items, onItemClick }: { currency: string, items: DataType[], onItemClick?: (item: DataType) => void }) {
  const date = dayjs(items[0].date).format("YYYY-MM-DD");
  const amount = items.reduce(
    (acc, item) => {
      const amt = DineroFactory({
        amount: Math.round(+item.total_amount.toFixed(3) * 1000),
        precision: 3,
        currency: asCurrency(item.currency, "SGD"),
      });
      return acc.add(amt);
    },
    DineroFactory({ amount: 0, currency: asCurrency(currency, "SGD") }),
  );
  const formatted_total_amount = amount.toFormat("$0,0.00");

  return (
    <Flex key={items[0].date} vertical style={{ marginBottom: 18, userSelect: "none", WebkitUserSelect: "none", msUserSelect: "none" }}>
      <Flex gap="middle" vertical={false} justify="space-between" style={{ opacity: 0.5 }}>
        <Typography.Text strong>{date}</Typography.Text>
        <Typography.Text strong>{formatted_total_amount}</Typography.Text>
      </Flex>
      <List
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => <ExpenseItem currency={currency} item={item} onClick={onItemClick} />}
      />
    </Flex>
  );
}

function ExpenseItem({ currency, item, onClick }: { currency: string, item: DataType, onClick?: (item: DataType) => void }) {
  const formatted_total_amount = DineroFactory({
    amount: Math.round(+item.total_amount.toFixed(3) * 1000),
    precision: 3,
    currency: asCurrency(item.currency, asCurrency(currency, "SGD")),
  }).toFormat("$0,0.00");

  return (
    <div
      key={item.id}
      onClick={() => setTimeout(() => onClick?.(item), 300)}
      style={{ flex: 1, position: "relative", overflow: "hidden" }}>
      <List.Item>
        <List.Item.Meta
          title={item.category}
          description={item.description}
        />
        <div>{formatted_total_amount}</div>
      </List.Item>
      <Ripples on="hover" />
    </div>
  );
}

function asCurrency(currency: string, defaultCurrency: DineroFactory.Currency): DineroFactory.Currency {
  if (CURRENCY_CODES.includes(currency.trim().toUpperCase() as DineroFactory.Currency)) {
    return currency as DineroFactory.Currency;
  }

  return defaultCurrency;
}

const CURRENCY_CODES: Array<DineroFactory.Currency> = [
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BOV",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BZD",
  "CAD",
  "CDF",
  "CHE",
  "CHF",
  "CHW",
  "CLF",
  "CLP",
  "CNY",
  "COP",
  "COU",
  "CRC",
  "CUC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "EUR",
  "FJD",
  "FKP",
  "GBP",
  "GEL",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HRK",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "JPY",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KPW",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRU",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MXV",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STN",
  "SVC",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USD",
  "USN",
  "UYI",
  "UYU",
  "UYW",
  "UZS",
  "VES",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XAG",
  "XAU",
  "XBA",
  "XBB",
  "XBC",
  "XBD",
  "XCD",
  "XDR",
  "XOF",
  "XPD",
  "XPF",
  "XPT",
  "XSU",
  "XTS",
  "XUA",
  "XXX",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL",
];